<template>
  <div class="body">
    <div v-if="deptList.length != 0" class="leftCellGroup">
      <van-cell-group style="float: left">
        <van-cell
          v-for="(item, index) in deptList"
          :key="index"
          :title="item.LabelType"
          :is-link="activeIndex == index ? true : false"
          :class="{ active: activeIndex == index ? true : false }"
          style="text-align: left; background-color: #f8f9f9"
          @click="geteh3102(item.LabelType, index)"
        >
          <template #right-icon>
            <van-icon
              name="play"
              v-if="activeIndex == index ? true : false"
              style="line-height: 24px"
            />
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="leftCellGroup" v-else>
      <van-empty description="科室加载错误" image="error" />
    </div>
    <van-cell-group class="rightCellGroup">
      <van-cell
        v-for="(item, index) in eh3102"
        :key="index"
        :title="item.bizDeptName"
        style="text-align: left"
        @click="routerPush(item)"
      />
      <van-empty description="暂无科室" v-if="eh3102.length == 0" />
    </van-cell-group>
    <van-dialog
      class="dialog"
      v-model="show"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <div>尊敬的各位患者及家属：</div>
      <div class="scrollbar">
        <p>
          1.防控疫情期间，为减少人员接触，我院现实行全院预约分时段挂号就诊，请您严格按照预约时间段提前挂号。
        </p>
        <p>2.就诊时请务必携带患者本人身份证前来本院就诊。</p>
        <p>
          3、请如实告知医务人员您的流调信息，否则我们将依法追究您的法律责任；
        </p>
      </div>
      <van-button type="primary" class="confirm-btn" @click="Close"
        >确认</van-button
      >
    </van-dialog>
    <van-dialog
      class="dialog"
      v-model="overflowShow1"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
      :overlayStyle="{ backgroundColor: 'rgba(0,0,0,0.1)' }"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>请先预约发热门诊核酸检测和抗原检测</p>
      <van-button class="cancle-btn" @click="overflowShow1 = false"
        >取消</van-button
      >
      <van-button type="primary" class="confirm-btn" to="/feverClinic"
        >确认</van-button
      >
    </van-dialog>
    <van-dialog
      class="dialog"
      v-model="overflowShow"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
      :overlayStyle="{ backgroundColor: 'rgba(0,0,0,0.5)' }"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>暂无就诊人信息，请添加就诊人</p>
      <van-button
        class="cancle-btn"
        @click="
          overflowShow = false;
          show = true;
        "
        >取消</van-button
      >
      <van-button type="primary" class="confirm-btn" @click="addPatient"
        >确认</van-button
      >
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";
import moment from "moment";

export default {
  data() {
    return {
      chooseItem: "",
      personId: "",
      list: [],
      activeIndex: null,
      deptList: [],
      eh3102: [],
      show: false,
      overflowShow: false,
      overflowShow1: false,
    };
  },
  methods: {
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (this.chooseItem.length == 0) {
        this.overflowShow = true;
        return;
      } else {
        if (this.$store.state.showDeptTips == true) {
          this.show = true;
        } else {
          this.show = false;
        }
      }
    },
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
                console.log(this.chooseItem);
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
            this.isHasPatient();
            sessionStorage.setItem(
              "CurrentJZPerson",
              JSON.stringify(this.chooseItem)
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    routerPush(item) {
      // 预约挂号支付状态为false
      // sessionStorage.setItem("appointPayState",false);
      this.$store.commit("setShowDeptTips", false);
      this.$router.push({
        path: "/doctors",
        query: {
          deptCode: item.bizDeptCode,
          LabelType: item.bizDeptName,
          type: this.$route.query.type,
        },
      });
    },
    /**
     * 当日挂号获取二级科室
     * @param parentCode
     * @param index
     */
    geteh3102(parentCode, index) {
      // if (parentCode == "发热门诊") {
      //   const CurrentJZPerson = JSON.parse(
      //     sessionStorage.getItem("CurrentJZPerson")
      //   );
      //   let param = {
      //     name: CurrentJZPerson.name,
      //     idNumber: CurrentJZPerson.idNumber,
      //   };
      //   this.$http.get("/api/hot/judge", param).then((result) => {
      //     if (!result.data.result) {
      //       // 为false则，没做核酸，弹出提示框，跳转页面去做核酸
      //       this.overflowShow1 = true;
      //       return;
      //     } else {
      //       // 为true，已做核酸，去挂号页面挂号
      //       this.$router.push({
      //         path: "/doctors",
      //         query: {
      //           LabelType: "发热门诊",
      //           deptCode: "401001",
      //           type: 0,
      //           id: result.data.orderid,
      //         },
      //       });
      //     }
      //   });
      // }
      let postData = {
        parentCode: parentCode,
        type: this.$route.query.type,
        // type为0，则开始日期结束日期都为当天
        // type为1，则开始日期为明天，结束日期为后天
        beginDate:
          this.$route.query.type == 0
            ? moment(new Date()).format("yyyy-MM-DD")
            : moment(new Date()).add(1, "days").format("yyyy-MM-DD"),
        endDate:
          this.$route.query.type == 0
            ? moment(new Date()).format("yyyy-MM-DD")
            : moment(new Date()).add(3, "days").format("yyyy-MM-DD"),
      };
      this.$http.get("/api/wlptappointment/eh3102", postData).then((res) => {
        this.activeIndex =
          index != null ? index : this.$store.state.deptActiveIndex;
        this.$store.commit("setDeptActiveIndex", this.activeIndex);
        this.eh3102 = res.data;
      });
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    getDeptList() {
      this.$http
        .get("/api/wlptappointment/eh3101", {
          type: this.$route.query.type,
        })
        .then((res) => {
          if (res.status === 200) {
            this.deptList = res.data;
            let deptActiveIndex = this.$store.state.deptActiveIndex;
            if (this.deptList[deptActiveIndex].LabelType != "发热门诊") {
              this.geteh3102(
                this.deptList[deptActiveIndex].LabelType,
                deptActiveIndex
              );
            }
          }
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
        });
    },
    Close() {
      this.show = false;
    },
  },
  mounted() {
    this.getList();
    this.getDeptList();
    this.$router.beforeEach((to, from, next) => {
      if (to.path == "/dept") {
        if (from.path == "/treatmentServices") {
          this.show = "true";
        } else {
          this.show = "false";
        }
      }
      next();
    });
  },
};
</script>

<style scoped>
.body {
  color: #666;
}

.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}

.dialog p {
  text-indent: 0.8rem;
  text-align: left;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.dialog-title {
  margin-bottom: 0.3rem;
}

.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}

.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}

.dialog p {
  text-indent: 0.8rem;
  text-align: left;
}

.active {
  color: #86acf4 !important;
}

.leftCellGroup {
  width: 4rem;
  margin: 0px;
  padding: 0px;
  height: calc(100vh - 46px);
  padding-right: 7px;
  padding-left: 0px;
  overflow-y: auto;
  float: left;
}
/deep/ .leftCellGroup .van-cell-group {
  width: 100% !important;
}
/deep/ .leftCellGroup .van-empty__description {
  padding: 0;
}

.rightCellGroup {
  width: 6rem;
  height: calc(100vh - 46px);
  overflow-y: auto;
  float: left;
}
.scrollbar {
  height: 5.5rem;
  overflow-y: auto;
}
</style>
